import { Component, Input } from '@angular/core';
import { faStar as faStarRegular, faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { faStar as faStarSolid, faChevronsUp, faChevronsDown, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { Observable, map, shareReplay } from 'rxjs';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BetService } from 'src/app/bet.service';
import { BlogService } from 'src/app/blog.service';
import { GeneralService } from 'src/app/general.service';
import { MatchBet } from 'src/app/match-bet.model';
import { MatchService } from 'src/app/match.service';

@Component({
    selector: 'app-value-bet-row',
    templateUrl: './value-bet-row.component.html',
    styleUrls: ['./value-bet-row.component.scss']
})
export class ValueBetRowComponent {

    @Input() educationSectionOnPage: boolean = true;
    @Input() play: MatchBet;
    @Input() singleMatch: boolean;
    @Input() proOnly: boolean;
    
    faStarSolid = faStarSolid;
    faStarRegular = faStarRegular;

    faChevronsUp = faChevronsUp;
    faChevronsDown = faChevronsDown;
    faTimes = faTimes;
    faCircleInfo= faCircleInfo;

    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
        map((detailedUser) => {
            return !!detailedUser?.app_metadata?.dimers_pro
        }),
        shareReplay(1)
    );

    constructor(
        public matchService: MatchService,
        public generalService: GeneralService,
        public betService: BetService,
        private authHelper: AuthHelperService,
        private blogService: BlogService,
        private gtmService: GoogleTagManagerService,
    ) {
    }

    oddsButtonClick() {
        this.betService.sharedLegalOnlineBettingData$.subscribe(data => {
            if (data) {
                this.betService.goToPromoLink(this.betService.bookmakerLink(this.play.bookmaker, this.play.linkDetails), this.play.bookmaker, 'quick-picks');
            }
            else {
                this.betService.setSharedModalData(true);
                this.betService.setSharedBookMakerData(this.betService.stylisedBookName(this.play.bookmaker));
                this.betService.setSharedOddsRedirectLinkData(this.betService.bookmakerLink(this.play.bookmaker, this.play.linkDetails));
            }
        });
    }

    openProModal() {
        // Open Pro modal
        const gtmTag = {
            event: "pro_modal_appearance",
            event_category: "masked-bet",
            // event_label: this.bookieCode,
        };

        this.gtmService.pushTag(gtmTag)
            .catch(e => {
                console.error("Google Tag Manager Script failed: " + e)
            });
        this.blogService.setProModalData(true);
    }

    jumpToSection(section:string): void {
        this.blogService.jumpToSection(section);
    }
}
