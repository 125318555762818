import { Component } from '@angular/core';

@Component({
  selector: 'app-dimers-data',
  templateUrl: './dimers-data.component.html',
  styleUrl: './dimers-data.component.scss'
})
export class DimersDataComponent {

}
