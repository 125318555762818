
<div *ngIf="!this.loading && !this.loadingError">
    <div *ngIf="!stateLegal">
        <section class="hero-section -mt-5 text-white">
            <div class="w-full max-w-[1270px] mx-auto px-[10px]">
                <div class="flex flex-row items-center py-4 sm:py-8 md:py-16">
                    <div class="basis-full md:basis-1/2 flex flex-col gap-6">
                        <h1 *ngIf="content?.title" class="text-2xl md:text-4xl lg:text-5xl font-bold text-neutral-100">
                            <!-- {{this.content?.state.title}} Sports Betting: Best {{this.content?.state.metadata.abbreviation}} Sportsbooks -->
                            {{content?.title}}
                        </h1>
                        <div *ngIf="content?.intro_paragraph" class="hidden sm:block font-semibold text-xl text-neutral-100">
                            <!-- Compare the best sportsbooks in {{this.content?.state.title}}  with our detailed analysis. -->
                            {{content?.intro_paragraph}}
                        </div>
                        <div>
                            Find out what are the best DFS offers in your state
                        </div>
                        <div>
                            Daily Fantasy is legal in {{this.content?.state.title}}.
                        </div>
                        <button class="w-full max-w-[200px] bg-highlight-100 h-10 rounded shadow-md text-sm font-semibold" (click)="this.jumpToSection('promotions')">
                            Explore DFS Offers
                        </button>
                    </div>
                    <div class="basis-1/2 hidden md:flex items-center justify-center relative self-stretch ">
                        <div class="blur-[150px] bg-highlight-100 h-full w-full absolute -z-10">
                        </div>
                        <img [src]="this.content?.state_map.url" alt="" width="500" height="295">
                    </div>
                </div>
            </div>
        </section>

        <section class="reviewer-info mt-4 sm:mt-8 bg-navy-100 px-6 py-4 rounded-lg" *ngIf="this.content?.author.author && this.content?.review_time">
            <app-reviewer-info [author]="this.content?.author.author" [reviewTime]="this.content?.review_time"></app-reviewer-info>
        </section>
    
        <section class="best-promos" id="promotions">
            <h2 class="heading">
                <span *ngIf="stateLegal">
                    Best Sportsbook Promotions in {{this.content?.state.title }}
                </span>
                <span *ngIf="!stateLegal">
                    Best DFS Offers in {{this.content?.state.title }}
                </span>
            </h2>
            <div class="promos-tabs">
                <swiper-container slides-per-view="auto" class="mySwiper">
                    <ng-container *ngFor="let bestBook of heroSportsbooks | keyvalue:sortingOffers;">
                        <swiper-slide>
                            <div class="promos-tab" [class]="bestBook.key" [class.active]="bestBookTab === bestBook.key"
                                (click)="this.toggleShow(bestBook.key)">
                                <img [src]="bestBook.value[0].metadata.sportsbook[0].metadata.promos_small_logo.imgix_url + '?auto=format,compress&h=36'" height="24" width="24" alt="logo">
                            </div>
                        </swiper-slide>
                    </ng-container>
                </swiper-container>
            </div>

            <div class="promos-items max-w-4xl">
                <ng-container *ngFor="let bestBook of heroSportsbooks | keyvalue:sortingOffers;">
                    <div class="best-promos-book" [class]="bestBook.key" [class.active]="bestBookTab === bestBook.key">
                        <ng-container *ngFor="let promo of bestBook.value">
                            <app-book-offer-banner [promo]="promo" pageLocation="best-book-in-content" [stateCode]="this.stateCode"></app-book-offer-banner>
                        </ng-container>
                   
                    </div>
                </ng-container>
            </div>

            <div class="p-4 text-base font-medium text-neutral-600 bg-navy-50 rounded">
                No sportsbook is best for everyone. What’s best for you might not be best for someone else. We hope this guide can help narrow down your options, regardless of your betting style or level of experience. 
            </div>
            </section>
        
        <section class="html-content" [innerHTML]="this.notLegalStateHtml | trustCosmic">
        </section>
    
        <section class="expert-details" *ngIf="this.content?.author?.author">
            <div class="py-4 px-6 rounded-lg bg-navy-100 flex flex-col gap-4">
                <div class="flex gap-2">
                    <div>
                        <img [src]="this.content?.author.author.metadata.author_thumbnail.url" alt="profile-img" width="48" height="48" class="rounded-full max-w-full h-auto align-middle border border-yellow-400 shadow-md">
                    </div>
                    <div>
                        <div class="font-bold text-base text-black" >
                            <a class="underline text-black hover:text-highlight-100" href="/news/author/{{this.content?.author.author.slug}}" routerLink="/news/author/{{this.content?.author.author.slug}}">{{this.content?.author.author.title}}</a>
                        </div>
                        <div class="font-medium text-sm text-neutral-400">
                            {{this.content?.author.author.metadata.title}}
                        </div>
                    </div>
                </div>
                <div class="text-base font-medium text-neutral-600" [innerHTML]="this.content?.author.author.content | trustCosmic">
                </div>
            </div>
        </section>
    </div>
    
    <div *ngIf="stateLegal">
        <app-masthead-offers 
        *ngIf="this.content?.state.metadata.status.value === 'Legal' || this.content?.state.metadata.status.value === 'Pre launch'"
            [status]="content?.state.metadata.status.value"
            [offers]="heroSportsbooks"
            [stateCode]="stateCode"
            [title]="content?.title" 
            [subTitle]="content.intro_paragraph" 
        >
        </app-masthead-offers>

        <section class="reviewer-info mt-4 sm:mt-8 bg-navy-100 px-6 py-4 rounded-lg"  *ngIf="!this.loading && !this.loadingError" >
            <div class="hidden md:flex md:flex-row flex-wrap gap-2 items-center">
                <div>
                    <img [src]="this.content?.author.author.metadata.author_thumbnail.url" alt="profile-img" width="48" height="48" class="rounded-full max-w-full h-auto align-middle border border-yellow-400 shadow-md">
                </div>
                <div class="flex flex-col ">
                    <div class="text-sm font-medium">
                        Written by <a class="underline text-black hover:text-highlight-100" href="/news/author/{{this.content?.author.author.slug}}" routerLink="/news/author/{{this.content?.author.author.slug}}">{{this.content?.author.author.title}}</a>, {{this.content?.author.author.metadata.title}}
                    </div>
                    <div class="text-sm font-medium flex flex-row gap-2">
                        <div>
                            Updated on {{this.content?.author.review_time | date:'mediumDate'}}
                        </div>
                        <span>&#8226;</span>
                        <div class="flex flex-row gap-1 items-baseline">
                            <fa-icon class="text-green-600" [icon]="faClipboardCheck"></fa-icon>
                            Fact checked
                        </div>
                        <span>&#8226;</span>
                        <div>
                            Reviewed by <a class="underline text-black hover:text-highlight-100" href="/news/author/nick-slade" routerLink="/news/author/nick-slade">Nick Slade</a>, Chief Content Officer
                        </div>
                    </div>
                </div>
            </div>
    
    
            <div class="flex flex-col gap-2 md:hidden">
                <div class="flex flex-row gap-2 items-center">
                    <div>
                        <img [src]="this.content?.author.author.metadata.author_thumbnail.url" alt="profile-img" width="48" height="48" class="rounded-full max-w-full h-auto align-middle border border-yellow-400 shadow-md">
                    </div>
                    <div class="text-xs font-medium">
                        Written by <a class="underline text-black hover:text-highlight-100" href="/news/author/{{this.content?.author.author.slug}}" routerLink="/news/author/{{this.content?.author.author.slug}}">{{this.content?.author.author.title}}</a>
                        <br/> {{this.content?.author.author.metadata.title}}
                    </div>
                </div>
                <div class="text-xs font-medium ">
                    <div class="flex flex-row gap-2">
                        <div>
                            Updated on {{this.content?.author.review_time | date:'mediumDate'}}
                        </div>
                        <div class="flex flex-row gap-1 items-baseline">
                            <fa-icon class="text-green-600" [icon]="faClipboardCheck"></fa-icon>
                            Fact checked
                        </div>
                    </div>
                    <div>
                        Reviewed by <a class="underline text-black hover:text-highlight-100" href="/news/author/nick-slade" routerLink="/news/author/nick-slade">Nick Slade</a>, Chief Content Officer
                    </div>
                </div>
            </div>
        </section>
        <!-- <section class="test-master-hero"> 
            test
        </section> -->
        <div class="flex gap-8 mt-8" *ngIf="!this.loading && !this.loadingError">
            <div class="hidden lg:block sticky h-screen top-3 w-full max-w-xs">
            <div class="max-h-screen overflow-y-auto pb-6">
                <ul class="text-gray-600 list-none m-0" role="list">
                    <li class="" >
                        <div class="flex items-baseline gap-2 py-2 px-4 text-highlight-100 bg-gray-100 rounded cursor-pointer" (click)="toggleOpen(0)">
                            <fa-icon *ngIf="this.navigationMenu[0]" class="flex self-end" [icon]="faSortUp"></fa-icon>
                            <fa-icon *ngIf="!this.navigationMenu[0]" class="flex" [icon]="faSortDown"></fa-icon>
                            <div>In This Guide</div>                
                        </div>
                        <ul role="list" class="list-none m-0 pl-4" *ngIf="this.navigationMenu[0]">
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('how-to-bet')" *ngIf="this.content?.new_how_to_bet">{{this.content?.new_how_to_bet.title}}</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('requirement')" *ngIf="this.content?.new_requirements" >{{this.content?.new_requirements.title}}</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('promotions')">Best <span *ngIf="stateCode !=='ontario'">Sportsbook Promotions</span> <span *ngIf="stateCode ==='ontario'">Sportsbooks</span> in {{this.content?.state.title}}</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('requirement')" *ngIf="this.content?.best_massachusetts_sportsbooks" >Best {{this.content?.state.title}} Sportsbooks: May 2023</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('choose-sportsbook' )" *ngIf="this.content?.choose_the_right_sports.title"> {{this.content.choose_the_right_sports.title}}</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('best-bet')" *ngIf="this.content?.best_bets" >{{this.content?.state.title}} Best Bets</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('state-sports')" *ngIf="this.content?.sports_to_bet">Sports You Can Bet on in {{this.content?.state.title}}</li>
                            <!-- <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('resources')">Resources</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('faq')">FAQ</li> -->
                        </ul>
                    </li>
                    <li class="mt-1">
                        <div class="flex items-baseline gap-2 py-2 px-4 text-highlight-100 bg-gray-100 rounded  cursor-pointer" (click)="toggleOpen(1)">
                            <fa-icon *ngIf="this.navigationMenu[1]" class="flex self-end" [icon]="faSortUp"></fa-icon>
                            <fa-icon *ngIf="!this.navigationMenu[1]" class="flex" [icon]="faSortDown"></fa-icon>
                            <div>Dimers' Experts</div>
                        </div>
                        <ul role="list" class="list-none m-0 pl-4"  *ngIf="this.navigationMenu[1]">
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('trust-dimers-experts')" *ngIf="this.content?.experts">Why You Can Trust Dimers' Experts</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('expert-tips')" *ngIf="this.content?.dimers_expert_tips.author">Dimers' Expert Tips</li>
                        </ul>
                    </li>
                    <li class="mt-1">    
                        <div class="flex items-baseline gap-2 py-2 px-4 text-highlight-100 bg-gray-100 rounded  cursor-pointer" (click)="toggleOpen(2)">
                            <fa-icon *ngIf="this.navigationMenu[2]" class="flex self-end" [icon]="faSortUp"></fa-icon>
                            <fa-icon *ngIf="!this.navigationMenu[2]" class="flex" [icon]="faSortDown"></fa-icon>
                            <div>{{this.content?.state.title}} Sports Betting</div>
                        </div>
                        <ul role="list" class="list-none m-0 pl-4"  *ngIf="this.navigationMenu[2]">
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('resources')" *ngIf="this.content?.resources?.content" >{{this.content?.state.title}} Sports Betting Resources</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('history')" *ngIf="this.content?.history">{{this.content?.state.title}} Sports Betting History</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('responsible-gambling')" *ngIf="this.content?.responsible_gambling">{{this.content?.state.title}} Responsible gambling</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('faq')" *ngIf="this.content?.faq_json">{{this.content?.state.title}} Sports Betting FAQs</li>
                            <li class="py-2 px-4 hover:bg-gray-100 rounded cursor-pointer mt-1 font-medium capitalize" (click)="this.jumpToSection('related-news')" *ngIf="this.content?.related_news">{{this.content?.state.title}} Related News</li>
                        </ul>
                    </li>
                </ul>
            </div>
    
        </div>
        <div class="max-w-3xl w-full lg:w-[72%] flex-1 lg:ml-4">
                <section class="guide" id="how-to-bet" *ngIf="this.content?.new_how_to_bet">
                <div class="text" [innerHTML]="this.content?.new_how_to_bet.content | trustCosmic">
                </div>
                <ng-container *ngIf="this.content?.how_to_bet_steps">
                    <div class="guide-steps grid grid-cols-1 md:grid-cols-4  max-md:divide-y divide-x border border-neutral-300 rounded-md shadow">
                        <div class="guide-text p-4"  *ngFor="let step of this.content?.how_to_bet_steps.data; let i = index">
                            <div class="guide-label text-base font-semibold text-highlight-100">{{ step.guideLabel }}</div>
                            <div class="guide-subtitle text-sm font-medium">
                            {{ step.guideSubtitle }}
                            </div>
                        </div>
                    </div>
                </ng-container>
                
                </section>
                <section class="requirements" id="requirement" *ngIf="this.content?.new_requirements">
                    <div class="text" [innerHTML]="this.content?.new_requirements.content | trustCosmic">
                    </div>
                    <div class="requirements-list grid grid-cols-1 md:grid-cols-2 gap-2" *ngIf="this.content?.requirements_list">
                    <div class="individual-requirement border border-neutral-300 rounded-md p-4 flex gap-4" *ngFor="let content of this.content?.requirements_list.data">
                        <div class="flex justify-center items-center h-10 w-10 border-2 border-highlight-100 rounded-full">
                            <!-- <span class="material-symbols-outlined  text-highlight-100">
                                {{content.icon}}
                            </span> -->
                            <!-- <img src="https://cdn.ciphersports.io/images/dimers-site/best-books/{{ content.icon }}-icon.svg" alt="icon"> -->
                            <img src="https://cdn.ciphersports.io/images/dimers-site/best-books/{{content.icon}}.svg" alt="icon" width="24" height="24">

                        </div>
                        <div class="requirement-text">
                        <div class="text-highlight-100 text-xs font-semibold">NECESSARY</div>
                        <div class="requirement-label font-medium text-sm">{{content.text}}</div>
                        </div>
                    </div>
                    </div>
                </section>
                <section class="best-promos" id="promotions">
                <h2 class="heading">
                    Best  <span *ngIf="stateCode !== 'ontario'">Sportsbook Promotions</span> <span *ngIf="stateCode === 'ontario'">Sportsbooks</span> in {{this.content?.state.title }}
                </h2>
                <div class="promos-tabs">
                    <swiper-container slides-per-view="auto" class="mySwiper">
                        <swiper-slide *ngFor="let bestBook of heroSportsbooks | keyvalue:sortingOffers;">
                            <div class="promos-tab" [class]="bestBook.key" [class.active]="bestBookTab === bestBook.key"
                                (click)="this.toggleShow(bestBook.key)">
                                <img [src]="bestBook.value[0].metadata.sportsbook[0].metadata.promos_small_logo.imgix_url + '?auto=format,compress&h=36'" height="24" width="24" alt="logo">
                            </div>
                        </swiper-slide>
                    </swiper-container>
                </div>
    
                <div class="promos-items">
                    <ng-container *ngFor="let bestBook of heroSportsbooks | keyvalue:sortingOffers;">
                        <div class="best-promos-book" [class]="bestBook.key" [class.active]="bestBookTab === bestBook.key">
                            <ng-container *ngFor="let promo of bestBook.value">
                                <app-book-offer-banner [promo]="promo" pageLocation="best-book-in-content" [stateCode]="this.stateCode"></app-book-offer-banner>
                            </ng-container>
                       
                        </div>
                    </ng-container>
                </div>
    
                <div class="p-4 text-base font-medium text-neutral-600 bg-navy-50 rounded">
                    No sportsbook is best for everyone. What’s best for you might not be best for someone else. We hope this guide can help narrow down your options, regardless of your betting style or level of experience. 
                </div>
                </section>
          
                <section class="mt-8 sm:mt-16 best-sportsbppks" id="best-sportsbppks" *ngIf="content?.best_massachusetts_sportsbooks" [innerHTML]="content?.best_massachusetts_sportsbooks">
                </section>
    
                <section class="general-seo" id="choose-sportsbook" *ngIf="this.content?.choose_the_right_sports" [innerHTML]="this.content?.choose_the_right_sports.content | trustCosmic">
                </section>
                <section class="choosing-the-right-sportsbook" *ngIf="this.content?.choosing_the_right_sportsbook">
                    <div class="sportsbooks-tab">
                        <swiper-container slides-per-view="auto" class="mySwiper">
                            <swiper-slide *ngFor="let item of objectKeys(this.content?.choosing_the_right_sportsbook); let i = index;">
                                <div class="px-4 border-t-2 w-24 h-24 flex flex-col justify-end items-center gap-4 cursor-pointer"  [class.active]="sportsbookTab === item" (click)="this.toggleSportsbook(item)" >
                                    <div>
                                        <img [src]="this.sportsbooks[item]?.metadata.promos_small_logo.imgix_url + '?auto=format,compress&h=35'" alt="sportbook icon" width="35" height="35">
                                    </div>
                                    <div class="font-bold uppercase text-sm sportsbook-title">
                                        {{item}}
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
    
                    <div class="sportsbooks-content">
                        <ng-container *ngFor="let item of objectKeys(this.content?.choosing_the_right_sportsbook)">
                            <div class="flex flex-col gap-2" [class.active]="sportsbookTab === item">
                                <div class="text" [innerHTML]="this.content?.choosing_the_right_sportsbook[item] | trustCosmic">
                                </div>
                            </div>
                        </ng-container>
                    </div>
    
                </section>
                <section class="experts" id="trust-dimers-experts" *ngIf="this.content?.experts">
                    <app-why-you-can-trust [data]="this.content?.experts"></app-why-you-can-trust>
                </section>
                <section class="expert-details" id="expert-tips" *ngIf="this.content?.dimers_expert_tips.author">
                    <h2 class="heading"> Dimers' Expert Tips</h2>
                    <div class="py-4 px-6 rounded-lg bg-navy-50 flex flex-col gap-4">
                        <div class="flex gap-2">
                            <div>
                                <img [src]="this.content?.dimers_expert_tips.author.metadata.author_thumbnail.url" alt="profile-img" width="48" height="48" class="rounded-full max-w-full h-auto align-middle border border-yellow-400 shadow-md">
                            </div>
                            <div>
                                <div class="font-bold text-base text-black">
                                    {{this.content?.dimers_expert_tips.author.title}}
                                </div>
                                <div class="font-medium text-sm text-neutral-400">
                                    {{this.content?.dimers_expert_tips.author.metadata.title}}
                                </div>
                            </div>
                        </div>
                        <p class="text-base font-medium text-neutral-600 tips" [innerHTML]="this.content?.dimers_expert_tips.tips | trustCosmic">
                        </p>
                    </div>
                </section>
                <section class="all-sports" id="state-sports" *ngIf="this.content?.sports_to_bet">
                    <h2 class="heading">Sports You Can Bet on in  {{this.content?.state.title }}</h2>
                    <div class="text" [innerHTML]="this.content?.sports_to_bet | trustCosmic">
                    </div>
                    <div class="all-sports-icons logo-slider-bg" *ngIf="content?.sports_icons">
                    <swiper-container slides-per-view="auto" space-between="32" class="sports-slider">
                        <swiper-slide *ngFor="let sport of this.content?.sports_icons">
                            <div class="sport-icon flex flex-col gap-2">
                                <div class="border border-highlight-100 rounded-full p-2 w-fit">
                                    <img [src]="sport.metadata.icon.url" alt="" width="45" height="45">
                                </div>
                                <div class="sport-string font-medium text-xs text-center">
                                    {{ sport.metadata.sport_short_code }}
                                </div>
                            </div>
                        </swiper-slide>
                    </swiper-container>
                
                    </div>
                </section>
                <section class="general-seo" id="best-bet" *ngIf="this.content?.best_bets"
                    [innerHTML]="this.content?.best_bets | trustCosmic">
                </section>
                
                <app-value-bets-block *ngIf="valueBetsSports" [sports]="valueBetsSports" [educationSectionOnPage]="false"></app-value-bets-block>
                <div class="text" *ngIf="this.content?.state_disclaimer" [innerHTML]="this.content?.state_disclaimer | trustCosmic">
                </div>
                
                <section class="general-seo" id="history" *ngIf="this.content?.history"
                    [innerHTML]="this.content?.history | trustCosmic">
                </section>
                <section class="mt-4 history-timeline" *ngIf="this.content?.history_timeline">
                    <div class="text-base font-semibold">
                        {{this.content?.history_timeline.title}}
                    </div>
                    <ul role="list" class="list-none m-0">
                        <li *ngFor="let data of this.content?.history_timeline.data; let i = index">
                            <div class="relative pb-5">
                                <span class="absolute left-6 top-5 -ml-px h-full w-0.5 bg-highlight-100" aria-hidden="true" *ngIf="i !== (this.content?.history_timeline.data.length - 1)">
                                </span>
                                <div class="relative flex items-start space-x-4">
                                    <div class="relative px-2">
                                        <div
                                            class="flex h-8 w-8 items-center justify-center rounded-full bg-navy-50 ring-8">
                                            <img class="state-icon" [src]="this.content?.state.metadata.map_icon.url" alt="icon"
                                            width="18" height="18">
                                        </div>
                                    </div>
                                    <div class="min-w-0 flex-1">
                                        <div class="mt-1 flex flex-col items-start md:flex-row gap-x-2">
                                            <div
                                                class="inline-flex items-center gap-x-1.5 rounded-full px-3 py-1 text-sm font-medium text-neutral-600 ring-1 ring-inset ring-gray-300">
                                                <svg class="fill-highlight-100 h-1.5 w-1.5" viewBox="0 0 6 6" aria-hidden="true">
                                                    <circle cx="3" cy="3" r="3"></circle>
                                                </svg>{{data.date}}</div>
                                        </div>
                                        <div class="mt-3 text-base font-normal text-neutral-600">
                                            <p>{{data.content}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>
                <!-- <section class="general-seo" id="resources" *ngIf="this.content?.resources"
                    [innerHTML]="this.content?.resources | trustCosmic">
                </section> -->
                
                <section class="resources" id="resources" *ngIf="this.content?.resources?.content && this.content?.resources.terminologies_title">
                    <div *ngIf="this.content?.resources.content" [innerHTML]="this.content?.resources.content | trustCosmic">
                    </div>
                    <h3 class="heading" *ngIf="this.content?.resources.terminologies_title">
                        {{ this.content?.resources.terminologies_title }}
                    </h3>
                    <div class="terminologies-tab"  *ngIf="this.content?.resources.terminologies_title">
                        <swiper-container slides-per-view="auto" class="mySwiper">
                            <swiper-slide *ngFor="let item of this.content?.resources.terminologies">
                                <div class="px-4 border-t-2 w-24 h-24 flex flex-col justify-end items-center gap-4 cursor-pointer"  [class.active]="terminologiesTab === item.title" (click)="this.toggleTerminologies(item.title)">
                                    <!-- <span class="material-symbols-outlined text-4xl">
                                        {{item.icon}}
                                    </span> -->
                                    <img src="https://cdn.ciphersports.io/images/dimers-site/best-books/{{item.icon}}.svg" alt="icon" width="36" height="36">

                                    <div class="font-bold uppercase text-xs sportsbook-title">
                                        {{item.title}}
                                    </div>
                                </div>
                            </swiper-slide>
                        </swiper-container>
                    </div>
    
                    <div class="terminologies-content" *ngIf="this.content?.resources.terminologies_title">
                        <ng-container *ngFor="let item of this.content?.resources.terminologies">
                            <div class="flex flex-col gap-2" [class.active]="terminologiesTab === item.title">
                                <div class="text" [innerHTML]="item.content| trustCosmic">
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <div class="text" *ngIf="this.content?.resources.content_1"  [innerHTML]="this.content?.resources.content_1 | trustCosmic">
                    </div>
                </section>
    
                <section class="ma-responsible-gambling" id="responsible-gambling" *ngIf="this.content?.responsible_gambling"  >
                    <div class="flex flex-col gap-4" [innerHTML]="this.content?.responsible_gambling | trustCosmic">
                    </div>
                    <div *ngIf="this.content?.responsible_cta" class="flex flex-col gap-4 ">
                        <ng-container *ngFor="let item of this.content?.responsible_cta">
                            <a class="flex gap-2 sm:gap-4 rounded-md text-gray-900 hover:text-current shadow shadow-gray-400/50 overflow-hidden min-h-[60px]" href="{{item.link}}" target="_blank" rel="noopener noreferrer">
                                <div class="flex items-center justify-center font-medium text-base text-white w-16 bg-highlight-100">
                                    <span>{{this.content?.state.metadata.abbreviation}}</span>
                                </div>
                                <div class="flex flex-col justify-center flex-1">
                                    <div class="text-sm font-medium text-gray-900">
                                        {{item.title}}
                                    </div>
                                    <div class="text-sm font-normal text-gray-500">
                                        {{item.content}}
                                    </div>
                                </div>
                                <div class="flex items-center justify-center text-xl px-4 ml-auto">
                                    <fa-icon [icon]="faArrowUpRight"></fa-icon>
                                </div>
                            </a>
                        </ng-container>
                    </div>
                </section>
      
       
                <section class="faq" id="faq" *ngIf="this.content?.faq_json">
                    <h2 class="heading">
                    {{this.content?.state.title }} Sports Betting FAQs:
                    </h2>
                    <div>
                    <ng-container *ngFor="let content of this.content?.faq_json.data">
                        <app-faq-container [headerText]="content.question"
                        [bodyHTML]="content.answer"></app-faq-container>
                    </ng-container>
                    </div>
                </section>
                <section class="related-news" id="related-news" *ngIf="this.content?.related_news">
                    <h2 class="heading">
                        {{this.content?.related_news.title}}
                    </h2>
                    <div class="article-list">
                        <ng-container *ngFor="let article of this.content?.related_news.articles ">
                        <a class="article-link" [routerLink]="article.link">
                        <app-article-image-container class="article-image" [imageSrc]="article.img">
                        </app-article-image-container>
                        <div class="article-text">
                            <div class="article-author">
                            {{ article.author }}
                            </div>
                            <div class="article-title">
                            {{ article.title }}
                            </div>
                        </div>
                        
                        </a>
                    </ng-container>
                    </div>
                </section>
        </div>
        </div>
    
        <section class="expert-details" *ngIf="this.content?.author?.author">
            <app-author-section [author]="this.content?.author?.author"></app-author-section>
        </section>
    </div>


    <!-- remove this part becuase of removal of smartlook -->
	<!-- <section class="flex flex-row justify-between items-center bg-navy-100 rounded-lg mt-8 px-6 py-4" *ngIf="!this.loading && !this.loadingError">
		<div class="font-semibold text-xl text-black" [class.hidden]="likeButtonClicked || dislikeButtonClicked">
			Did you find this article useful?
		</div>
		<div class="font-semibold text-xl text-black" [hidden]="!likeButtonClicked && !dislikeButtonClicked">
			Thanks for your feedback!
		</div>
		<div class="flex flex-row gap-4">
			<button class="h-10 w-10 flex items-center justify-center bg-white rounded-full" (click)="likeButtonClick('like'); likeButtonClicked = true" [disabled]="likeButtonClicked || dislikeButtonClicked" [class.text-highlight-100]="likeButtonClicked">
				<fa-icon class="text-xl" [icon]="faThumbsUp"></fa-icon>
			</button>
			<button class="h-10 w-10 flex items-center justify-center bg-white rounded-full" (click)="likeButtonClick('dislike'); dislikeButtonClicked = true" [disabled]="likeButtonClicked || dislikeButtonClicked" [class.text-highlight-100]="dislikeButtonClicked">
				<fa-icon class="text-xl" [icon]="faThumbsDown"></fa-icon>
			</button>
		</div>
	</section> -->

	<div [class.show-scrollTop]="windowScrolled" class="back-to-top" (click)="scrollToTop()">
		<fa-icon [icon]="faArrowUp"></fa-icon>
	</div>
</div>
<div class="loading" *ngIf="this.loading && !this.loadingError">
	Loading...
</div>
<div class="loading" *ngIf="this.loadingError">
	<span>
		Sorry, something went wrong loading this page. Please <span class="retry-link" (click)="retrieveDataFromCosmic()">try again</span>.
	</span>
</div>
