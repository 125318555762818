<div class="bookie-logo-row bg-AIDimers-grey rounded-xl flex flex-col gap-2 sm:gap-6 justify-center p-4 md:p-8 mt-4">
    <h2 class="text-base md:text-2xl font-bold text-gray-900 leading-7 text-center">
        We Compare {{ title }} Odds From the Best Sportsbooks
    </h2>
    <div class="flex justify-between md:justify-around items-center gap-2 md:gap-6 flex-wrap">
        <div *ngFor="let option of bookOptions" class="flex flex-col gap-2 items-center justify-start">
            <img loading="lazy" class="rounded-md overflow-hidden h-10 w-10 md:h-20 md:w-20" [src]="option | bookIcon" alt="" width="80" height="80">
            <div class="text-sm md:text-xl text-gray-900 leading-7 font-semibold md:font-bold">{{ betService.stylisedBookName(option) }}</div>
        </div>
    </div>
    <a routerLink="/multiple-sportsbooks-guide"
        class="bg-white max-sm:flex-1 flex justify-center h-9 px-2 sm:px-4 py-2 text-gray-900 gap-2.5 sm:gap-3 items-center shadow shadow-slate-300 hover:bg-slate-200 hover:text-gray-900 rounded text-sm font-semibold"
    >
        Why Join Multiple Sportsbooks?
    </a>
</div>