import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID } from '@angular/core';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
@Component({
    selector: 'app-book-offer-banner',
    templateUrl: './book-offer-banner.component.html',
    styleUrls: ['./book-offer-banner.component.scss']
})
export class BookOfferBannerComponent {
    @Input() promo: any;
    @Input() pageLocation?: string;
    @Input() stateCode?: string;

    browserMode = false;
    faChevronDown = faChevronDown;
    faChevronUp = faChevronUp;
    offerDropdownOpen: boolean = false;

    constructor(
        @Inject(PLATFORM_ID) platformId: string,
        private gtmService: GoogleTagManagerService,
        @Inject(DOCUMENT) public document: Document,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
    }

    redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bookmaker: any) {
        // if (!this.excludeBookies(bookmaker?.slug || "")) {
        //     queryParams.c = (this.router.url.split("/")[1] || 'home') + (
        //         this.authHelper.user
        //             ? ('|' + this.authHelper.user.id)
        //             : '|visitor'
        //     );
        // }
        if (bookmaker.slug === 'underdog-fantasy') {
            delete queryParams['promo']
        }
        const combinedUrl = url + (url.includes("?") ? "&" : "?") + new URLSearchParams(queryParams).toString();
        if (this.browserMode) {
            const gtmTag = {
                event: "exit",
                event_category: "reviews",
                event_label: bookmaker.slug,
                event_location: this.pageLocation
            };

            this.gtmService.pushTag(gtmTag)
                .then(() => {
                    window.open(combinedUrl, "_self");
                })
                .catch(e => {
                    console.error("Google Tag Manager Script failed: " + e)
                    window.open(combinedUrl, "_self");
                });
        }
    }

    convertCase(str: string): string {
        const lower = String(str).toLowerCase()
        return lower.replace(/(^| )(\w)/g, function (x) {
            return x.toUpperCase()
        })
    }

    toggleOfferDropdown(event, slug): void {
        event.stopPropagation();
        this.offerDropdownOpen = !this.offerDropdownOpen;

    }

    getReviewPath(): string | null {
        const sportsbook = this.promo.metadata?.sportsbook[0];
        
        if (sportsbook?.metadata?.review) {
          return `/best-books/reviews/${sportsbook.slug}`;
        }
        
        if (sportsbook?.metadata?.dfs_review) {
          return `/dfs/reviews/${sportsbook.slug}`;
        }
        
        if (sportsbook?.metadata?.canadian_reviews) {
          return `/ca/betting/reviews/${sportsbook.slug}`;
        }
      
        return null; // No review path available
    }
}
