<section class="max-w-5xl px-[10px] ml-auto mr-auto pb-12">
    <div>
        <div class="text-2xl text-gray-900 text-center font-semibold items-center">
            Dimers data, as seen on...
        </div>
        <div class="grid grid-cols-4 gap-4 items-center justify-items-center mt-8">
            <img  loading="lazy" class="max-sm:h-8" src="https://imgix.cosmicjs.com/82f615c0-6596-11ef-8a1c-2b941b6315d1-Fox-sports-logo.svg?w=200&auto=format,compress" alt="Fox Sports icon" width="81" height="48">
            <img  loading="lazy" class="max-sm:h-8" src="https://imgix.cosmicjs.com/831117d0-6596-11ef-8a1c-2b941b6315d1-ESPN-White-logo-1.svg?w=200&auto=format,compress" alt="ESPN icon" width="194" height="48">
            <img  loading="lazy" class="max-sm:h-8" src="https://imgix.cosmicjs.com/82f4b630-6596-11ef-81cc-bdfc4d6f451d-USA-today-logo.svg?w=200&auto=format,compress" alt="USA Today icon" width="135" height="48">
            <img  loading="lazy" class="max-sm:h-8" src="https://imgix.cosmicjs.com/83107b90-6596-11ef-81cc-bdfc4d6f451d-forbes-logo.svg?w=200&auto=format,compress" alt="Forbes icon"  width="179" height="48">
        </div>
    </div>
</section>