import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BetService } from 'src/app/bet.service';
import { BookPipesModule } from 'src/app/book-pipes/book-pipes.module';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-bookie-logo-row',
  standalone: true,
  imports: [ CommonModule,BookPipesModule, RouterModule] , 
  templateUrl: './bookie-logo-row.component.html',
  styleUrl: './bookie-logo-row.component.scss'
})
export class BookieLogoRowComponent {

    @Input() title?:string;
    @Input() bookOptions:any;

    constructor(
        public betService: BetService
      ) {}
}
