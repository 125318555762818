import { Component, Inject, PLATFORM_ID, Input, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';

import { isPlatformBrowser } from '@angular/common';
import { faStar as faStarSolid, faStarHalfStroke,faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRight} from '@fortawesome/pro-light-svg-icons';


@Component({
  selector: 'app-masthead-offers',
  templateUrl: './masthead-offers.component.html',
  styleUrls: ['./masthead-offers.component.scss']
})
export class MastheadOffersComponent implements OnInit{
    @Input() status?:string;
    @Input() offers?:any;
    @Input() stateCode?:string;
    @Input() title?: string;
    @Input() subTitle?: string;
    faStarFull = faStarSolid;
    faStarHalf = faStarHalfStroke;
    faArrowUpRight = faArrowUpRight;
    offerDropdownOpen: boolean[] = [];
    faChevronDown = faChevronDown; 
    faChevronUp = faChevronUp;
    browserMode = false;


    constructor(
        public authHelper: AuthHelperService,
        private gtmService: GoogleTagManagerService,
        @Inject(PLATFORM_ID) platformId: string,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        Object.keys(this.offers).forEach((item, index) => {
            this.offerDropdownOpen[index] = true;
        });
    }

    redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bookmaker: any) {
        // if (!this.excludeBookies(bookmaker?.slug || "")) {
        //     queryParams.c = (this.router.url.split("/")[1] || 'home') + (
        //         this.authHelper.user
        //             ? ('|' + this.authHelper.user.id)
        //             : '|visitor'
        //     );
        // }
        if (bookmaker.slug === 'underdog-fantasy') {
            delete queryParams['promo']
        }
        const combinedUrl = url + (url.includes("?") ? "&" : "?") + new URLSearchParams(queryParams).toString();
        if (this.browserMode) {
            const gtmTag = {
                event: "exit",
                event_category: "best-book",
                event_label: bookmaker.slug,
                event_location: "best-book-masthead"  
            };

            this.gtmService.pushTag(gtmTag)
                .then(() => {
                    window.open(combinedUrl, "_self");
                })
                .catch(e => {
                    console.error("Google Tag Manager Script failed: " + e)
                    window.open(combinedUrl, "_self");
                });
        }

    }

    //sorting offers based on sportsbook index field
    sortingOffers = (a, b) => {
        return a.value[0].metadata.sportsbook[0].metadata.index > b.value[0].metadata.sportsbook[0].metadata.index ? 1 : -1;
    }

    toggleOfferDropdown(event, index: number, slug: string): void {
        event.stopPropagation();
        this.offerDropdownOpen[index] = !this.offerDropdownOpen[index];
    }

    getReviewPath(offer:any): string | null {
        const sportsbook = offer.value[0]?.metadata?.sportsbook[0];
  
        if (sportsbook?.metadata?.review) {
          return `/best-books/reviews/${sportsbook.slug}/`;
        }
        
        if (sportsbook?.metadata?.dfs_review) {
          return `/dfs/reviews/${sportsbook.slug}`;
        }
        
        if (sportsbook?.metadata?.canadian_reviews) {
          return `/ca/betting/reviews/${sportsbook.slug}`;
        }
      
        return null; // No review path available
    }
    
    hasReview(offer:any): boolean {
        const sportsbook = offer.value[0]?.metadata?.sportsbook[0]?.metadata;
        return !!(sportsbook?.review || sportsbook?.dfs_review || sportsbook?.canadian_reviews);
    }
}
