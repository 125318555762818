import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, HostListener, Inject, Input, OnChanges, OnInit, Optional, PLATFORM_ID, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { faCheck, faStar as faStarRegular, faSquare, faTimes } from "@fortawesome/pro-regular-svg-icons";
import { faChevronDown, faChevronUp, faStar as faStarSolid, faCheckSquare, faSliders, faArrowsUpDown } from "@fortawesome/pro-solid-svg-icons";
import { RESPONSE } from 'src/express.tokens';
import { compareAsc, differenceInHours, format, max, parseJSON } from 'date-fns';
import { map, Observable, shareReplay } from 'rxjs';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BetService } from 'src/app/bet.service';
import { BlogService } from 'src/app/blog.service';
import { BOOKMAKERS } from 'src/app/bookmakers';
import { GeneralService } from 'src/app/general.service';
import { LocalisationService } from 'src/app/localisation.service';
import { MatchBet } from 'src/app/match-bet.model';
import { Match } from 'src/app/match.model';
import { MatchService } from 'src/app/match.service';
import { SportDataService } from 'src/app/sport-data.service';

@Component({
  selector: 'app-value-bets-block',
  templateUrl: './value-bets-block.component.html',
  styleUrls: ['./value-bets-block.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ValueBetsBlockComponent implements OnInit, OnChanges {

    @Input() educationSectionOnPage: boolean = true;
	@Input() sports: Array<string>;
	@ViewChild("blockBody") blockBody: ElementRef;

	book = "all";
	bookDropdownOpen = false;
	bookOptions: Array<string> = [];
	browserMode = false;
	blockInitStarted = false;
	filterBetTypes = ["line", "total", "h2h", "other"];
	filterDropdownOpen = false;
    filterBetDropdownOpen:boolean = false;
	filterSports = [];
	filterTimeframe = 168;
	freeLimit = 3;
	valueBetsSports: Array<string> = [];
	valuePlays: Array<MatchBet> = [];
	valuePlaysError = false;
	valuePlaysLoading = true;
	startingDisplayLimit = 20;
	currentDisplayLimit = 0;
	betSort = "date";
	displayedValuePlays: Array<MatchBet> = [];
	showMoreVisible = false;
	proBannerText = "";
	hiddenBetCount = 0;
	maxHiddenEdge = 0;
    totalBets:number;

    mobileFilterOpen: boolean = false;
	sportsToggle: boolean = false;
    betTypesToggle:boolean = false;
    betTypes:string[] = ["line", "total", "h2h", "other"];
    sortToggle: boolean = false;
    currentAvailableBets:number = 0;

    sortCategoryLabel: string = 'Sort: Default';
	sortCategories = [
		{
			label: 'Game Time (earliest-latest)',
            text: "Game Time",
			sort: 'date',
		},
		{
			label: 'Game Time (latest-earliest)',
            text: "Game Time",
			sort: '-date',
		},
		{
			label: 'Edge (high-low)',
            text: "Edge",
			sort: 'edge',
		},
		{
			label: 'Edge (low-high)',
            text: "Edge",
			sort: '-edge',
		},
		{
			label: 'Probability (high-low)',
            text: "Probability",
			sort: 'winprob',
		},
		{
			label: 'Probability (low-high)',
            text: "Probability",
			sort: '-winprob',
		},
	];

	proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
		map((detailedUser) => {
			return !!detailedUser?.app_metadata?.dimers_pro
		}),
		shareReplay(1)
	);

	latestLastUpdated = null;

	faChevronUp = faChevronUp;
	faChevronDown = faChevronDown;
	faCheck = faCheck;
	faStarSolid = faStarSolid;
	faStarRegular = faStarRegular;
	faTimes = faTimes;
	faSquare = faSquare;
    faCheckSquare = faCheckSquare;
    faSliders = faSliders;
    faArrowsUpDown = faArrowsUpDown;
	constructor(
		// private errorHandler: ErrorHandler,
		private matchService: MatchService,
		private sportDataService: SportDataService,
		private generalService: GeneralService,
		public betService: BetService,
		public localisationService: LocalisationService,
		private authHelper: AuthHelperService,
		@Inject(PLATFORM_ID) platformId: object,
		@Optional() @Inject(RESPONSE) private response: any,
		private blogService: BlogService,
		private gtmService: GoogleTagManagerService,
	) {
		this.browserMode = isPlatformBrowser(platformId);
	}

	prepareBlock(): void {
		this.blockInitStarted = true;

		this.valuePlaysError = false;
		this.valuePlaysLoading = true;

		this.valueBetsSports = this.sports.map(s => s.toUpperCase());
		this.currentDisplayLimit = this.startingDisplayLimit;
		this.proUser$.subscribe((pro) => {
			if (pro === false) {
				// take one away from the number of currently shown bets, to add space for the pro banner
				// this.startingDisplayLimit -= 1;
				this.currentDisplayLimit -= 1;
			}
		})

		// By default, show plays from all sports
		this.filterSports = this.valueBetsSports.slice();
		this.bookOptions = BOOKMAKERS;

		// this.generalService.initialiseBlock(this.el);

		// if (typeof window !== "undefined" && typeof document !== "undefined") {
		// 	document.addEventListener("click", event => {
		// 		if ((event.target as Element).closest(".book-dropdown") === null) {
		// 			this.bookDropdownOpen = false;
		// 		}

		// 		if ((event.target as Element).closest(".filter-dropdown") === null) {
		// 			this.filterDropdownOpen = false;
		// 		}
		// 	});
		// }

		this.matchService.getGeoData().then(() => {
			this.retrieveValueBets();
		}, () => {
			this.retrieveValueBets();
		});
	}
	
	@HostListener("click", ["$event"])
	checkForInternalLinkClick(event: MouseEvent): void {
		this.generalService.checkForInternalLinkClick(event);
	}

	@HostListener("window:click", ["$event"])
	closeOpenDropdowns(event: MouseEvent): void {
		if ((event.target as Element).closest(".book-dropdown") === null) {
			this.bookDropdownOpen = false;
		}

		if ((event.target as Element).closest(".filter-dropdown") === null) {
			this.filterDropdownOpen = false;
		}

        if ((event.target as Element).closest(".filter-dropdown") === null) {
			this.filterBetDropdownOpen = false;
		}
        if ((event.target as Element).closest(".sort-btn") === null) {
			this.sortToggle = false;
		}
	}

	ngOnInit(): void {
		if (this.sports && !this.blockInitStarted) {
			this.prepareBlock();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.blockInitStarted && changes.sports.currentValue) {
			this.prepareBlock();
		} else if (changes.sports?.currentValue && changes.sports?.previousValue
			&& changes.sports.currentValue !== changes.sports.previousValue) {
			this.prepareBlock();
		}
	}

	setFilterTimeframe = (frame: number): void => {
		this.proUser$.subscribe(pro => {
			if (pro) {
				this.filterTimeframe = frame;
				this.currentDisplayLimit = this.startingDisplayLimit;
				this.proUser$.subscribe((pro) => {
					if (pro === false) {
						// take one away from the number of currently shown bets, to add space for the pro banner
						// this.startingDisplayLimit -= 1;
						this.currentDisplayLimit -= 1;
					}
				})
				this.setDisplayedValuePlays();
			} else {
				// Open Pro modal
				const gtmTag = {
					event: "pro_modal_appearance",
					event_category: "timeframe-toggle",
					// event_label: this.bookieCode,
				};
			  
				this.gtmService.pushTag(gtmTag)
					.catch(e => {
						console.error("Google Tag Manager Script failed: " + e)
					});
				this.blogService.setProModalData(true);
			}
		})
	};

	toggleBookDropdown = (): void => {
		this.bookDropdownOpen = !this.bookDropdownOpen;
	};

	toggleSportDropdown = (): void => {
		this.filterDropdownOpen = !this.filterDropdownOpen;
	};

    toggleBetDropdown() {
        this.proUser$.subscribe(pro => {
			if (pro) {
                this.filterBetDropdownOpen = !this.filterBetDropdownOpen;
			} else {
				// Open Pro modal
				const gtmTag = {
					event: "pro_modal_appearance",
					event_category: "filter-dropdown",
					// event_label: this.bookieCode,
				};
			  
				this.gtmService.pushTag(gtmTag)
					.catch(e => {
						console.error("Google Tag Manager Script failed: " + e)
					});
				this.blogService.setProModalData(true);
			}
		})

    }

	showMorePicks = (): void => {
		this.currentDisplayLimit += this.startingDisplayLimit;
		this.setDisplayedValuePlays();
	};

	setBetSort(item): void {
        this.sortToggle = false;
		this.proUser$.subscribe(pro => {
			if (pro) {
                this.sortCategoryLabel = 'Sort: ' + item.text;
                this.betSort = item.sort;
				this.currentDisplayLimit = this.startingDisplayLimit;
				this.proUser$.subscribe((pro) => {
					if (pro === false) {
						// take one away from the number of currently shown bets, to add space for the pro banner
						// this.startingDisplayLimit -= 1;
						this.currentDisplayLimit -= 1;
					}
				})
				this.setDisplayedValuePlays();
			} else {
				// Open Pro modal
				const gtmTag = {
					event: "pro_modal_appearance",
					event_category: "sort-toggle",
					// event_label: this.bookieCode,
				};
			  
				this.gtmService.pushTag(gtmTag)
					.catch(e => {
						console.error("Google Tag Manager Script failed: " + e)
					});
				this.blogService.setProModalData(true);
			}
		})
	}

	lastUpdatedString = (): string => {
		if (!this.latestLastUpdated) {
			return "Not Available";
		} else {
			return format(this.latestLastUpdated, "h:mma',' MMM d");
		}
	};

	retrieveValueBets = (): void => {
		this.valuePlaysLoading = true;
		this.valuePlaysError = false;
		let bookmakerList: Array<string> = [];
		bookmakerList = BOOKMAKERS;


		this.sportDataService.getUpcomingGames(this.valueBetsSports, true, true, bookmakerList, this.localisationService.getLocaleObject().sportExclusiveBookmakers)
			.subscribe((matches) => this.postMatchesFetch(matches), (err) => {
				// if (err !== "API_CALL_FAILED") {
				// 	this.errorHandler.handleError(err);
				// 	// console.error(err);
				// }

				this.valuePlaysError = true;
			});
		
	};

	postMatchesFetch = (matches: Array<Match>): void => {

		matches.forEach((match: Match) => {
			match.MatchData.UserDate = parseJSON(match.MatchData.Date);
		});
		
		const filteredMatches: Match[] = matches
			.filter(g => this.valueBetsSports.includes(g.MatchData.Sport.toUpperCase())
				&& this.matchService.isUpcoming(g))
			.sort((a,b) => this.matchService.gameDisplaySort(a,b));

		this.valuePlays = filteredMatches.map(match => {
			if (!match.aggregatedBestBets || match.aggregatedBestBets.length === 0) { return []; };

			const matchPlays = match.aggregatedBestBets.filter(bet => bet.edge > 2);

			matchPlays.forEach(play => play.match = {...match, aggregatedBestBets: []});

			return matchPlays;
		}).reduce((a, c) => a.concat(c), []);
		this.setDisplayedValuePlays();
        this.totalBets = this.valuePlays.filter(p => this.fitsValuePlaysFilter(p, 168)).length;
        
		if (this.valuePlays.length > 0 && this.valuePlays.some(p => p.match.PreData.LastUpdated)) {
			this.latestLastUpdated = max(this.valuePlays.map(p => {
				try {
					if (p.match.PreData.LastUpdated) {
						return parseJSON(p.match.PreData.LastUpdated);
					} else {
						return 0;
					}
				} catch {
					return 0;
				}
			}));

			// if on server-side rendering and able to send response headers:
			if (this.response) {
				// if last updated time exists, send response header indicating that it is when the page content was last modified
				this.response.set("Last-Modified", this.latestLastUpdated.toUTCString())
			}
		} else {
			this.latestLastUpdated = null;
		}


		this.valuePlaysLoading = false;
	}

	setBook = (mode: string): void => {
		this.book = mode;
		this.bookDropdownOpen = false;
		this.setDisplayedValuePlays();
	};

    toggleAllFilterBetType = ():void =>{
        if(this.filterBetTypes.length === this.betTypes.length){
            this.filterBetTypes = [];
        } else {
            this.filterBetTypes = this.betTypes.slice();
        }
        this.currentDisplayLimit = this.startingDisplayLimit;
		this.proUser$.subscribe((pro) => {
			if (pro === false) {
				// take one away from the number of currently shown bets, to add space for the pro banner
				// this.startingDisplayLimit -= 1;
				this.currentDisplayLimit -= 1;
			}
		})
		this.setDisplayedValuePlays();
    }

	toggleFilterBetType = (type: string): void => {
		if (this.filterBetTypes.includes(type)) {
			this.filterBetTypes.splice(this.filterBetTypes.indexOf(type), 1);
		} else {
			this.filterBetTypes.push(type);
		}
        this.currentDisplayLimit = this.startingDisplayLimit;
		this.proUser$.subscribe((pro) => {
			if (pro === false) {
				// take one away from the number of currently shown bets, to add space for the pro banner
				// this.startingDisplayLimit -= 1;
				this.currentDisplayLimit -= 1;
			}
		})
		this.setDisplayedValuePlays();
	};

	toggleAllFilterSports = (): void => {
		if (this.filterSports.length === this.valueBetsSports.length) {
			this.filterSports = [];
		} else {
			this.filterSports = this.valueBetsSports.slice();
		}
		this.currentDisplayLimit = this.startingDisplayLimit;
		this.proUser$.subscribe((pro) => {
			if (pro === false) {
				// take one away from the number of currently shown bets, to add space for the pro banner
				// this.startingDisplayLimit -= 1;
				this.currentDisplayLimit -= 1;
			}
		})
		this.setDisplayedValuePlays();
	}

	toggleFilterSport = (sport:string):void => {
		if (this.filterSports.includes(sport)) {
			this.filterSports.splice(this.filterSports.indexOf(sport), 1);
		} else {
			this.filterSports.push(sport);
		}
		this.currentDisplayLimit = this.startingDisplayLimit;
		// this.proUser$.subscribe((pro) => {
		// 	if (pro === false) {
		// 		// take one away from the number of currently shown bets, to add space for the pro banner
		// 		// this.startingDisplayLimit -= 1;
		// 		this.currentDisplayLimit -= 1;
		// 	}
		// })
		this.setDisplayedValuePlays();
	}

	setDisplayedValuePlays(): void {
		const displayableValuePlays = this.valuePlays
			.filter(p => this.fitsValuePlaysFilter(p))
			.sort((a,b) => {
				if (this.betSort === "date") {
					return compareAsc(a.match.MatchData.UserDate, b.match.MatchData.UserDate);
				}
                if (this.betSort === "-date") {
					return compareAsc(b.match.MatchData.UserDate, a.match.MatchData.UserDate);
				}
                if(this.betSort === "winprob"){
                    return b.winProb - a.winProb;
                }
                if(this.betSort === "-winprob"){
                    return a.winProb - b.winProb;
                }
                if(this.betSort === "edge"){
                    return b.edge - a.edge;
                }
                if(this.betSort === "-edge"){
                    return a.edge - b.edge;
                }
				return b.edge - a.edge;
			});

            this.currentAvailableBets = displayableValuePlays.length;
		this.displayedValuePlays = displayableValuePlays
			.slice(0, this.currentDisplayLimit);

		if (displayableValuePlays.length > this.currentDisplayLimit) {
			this.showMoreVisible = true;
		} else {
			this.showMoreVisible = false;
		}

		if (displayableValuePlays.length > this.freeLimit) {
			this.hiddenBetCount = displayableValuePlays.length - this.freeLimit;
			this.maxHiddenEdge = Math.max(...displayableValuePlays.slice(this.freeLimit).map(b => b.edge));
			this.proBannerText = `Access ${this.hiddenBetCount} more ${this.hiddenBetCount === 1 ? 'bet with' : 'bets including'} ${this.indefinitePrefix(this.maxHiddenEdge.toFixed(0))} ${this.maxHiddenEdge.toFixed(0)}% edge`
		} else {
			this.proBannerText = "";
		}
    }
	

	indefinitePrefix(value: string) {
		// possible values in this context which could be prefaced with "an" are 8, 11 and 18
		if (/^(8|11|18)$/.test(value)) {
		  return "an";
		}
	
		// all others are prefaced with "a"
		return "a";
	  }

	fitsValuePlaysFilter(play: MatchBet, timeframe?:number): boolean {
		if (this.book !== "all") {
			if (play.bookmaker !== this.book) {
				return false;
			}
		} else {
			// if all bookmakers is set, hide this bet if there's another one for the same match and market with a higher edge
			const similarBets = this.valuePlays.filter(p => p.match === play.match && p.type === play.type);
			if (similarBets.length > 0) {
				if (similarBets.some(otherBet => this.betOutranksBet(otherBet, play))) {
					return false;
				}
			}
		}
		if (this.filterBetTypes.length >= 0) {
			if (!this.filterBetTypes.includes("other") && ["firstset"].includes(play.type)) {
				return false;
			}
			if (!this.filterBetTypes.includes(play.type)) {
				return false;
			}
		}

		// if (this.filterSports.length > 0) {
		if (!this.filterSports.includes(play.match.MatchData.Sport.toUpperCase())) {
			return false;
		}
		// }

		// check timeframe
        
        if(!this.matchService.isUpcoming(play.match)
			|| differenceInHours(play.match.MatchData.UserDate, new Date()) > (timeframe ? timeframe : this.filterTimeframe)) {
			return false;
		}

		return true;
	}

	betOutranksBet(otherBet: MatchBet, play: MatchBet): boolean {
		if (otherBet.edge !== play.edge) {
			return otherBet.edge > play.edge;
		}

		// if it's a dead heat for best edge, only return the one whose bookmaker comes first alphabetically
		return otherBet.bookmaker > play.bookmaker;
	}

	

	openProModal(): void {
		const gtmTag = {
			event: "pro_modal_appearance",
			event_category: "insert-banner",
			// event_label: this.bookieCode,
		};
	  
		this.gtmService.pushTag(gtmTag)
			.catch(e => {
				console.error("Google Tag Manager Script failed: " + e)
			});
		this.blogService.setProModalData(true);
	}

    toggleSortOptions() {
        this.sortToggle = !this.sortToggle;
    }

    openFilter() {
        this.proUser$.subscribe(pro => {
			if (pro) {
                this.mobileFilterOpen = true;
			} else {
				// Open Pro modal
				const gtmTag = {
					event: "pro_modal_appearance",
					event_category: "filter-dropdown",
					// event_label: this.bookieCode,
				};
			  
				this.gtmService.pushTag(gtmTag)
					.catch(e => {
						console.error("Google Tag Manager Script failed: " + e)
					});
				this.blogService.setProModalData(true);
            }

		})
    }
}
