<!-- Unlocked State - for Pro Users or for first few rows-->
<ng-container *ngIf="!proOnly || (proUser$ | async) === true">
    <div class="value-bet-info new-layout large-only">
        <div class="game-info">
            <div class="team-logo">
                <app-team-icon
                    *ngIf="(play.type === 'h2h' || play.type === 'line') && (play.match.MatchData.Sport | lowercase) !== 'ten'"
                    class="team-logo" alt="" [sportCode]="play.match.MatchData.Sport"
                    [team]="play.bet === 'home' ? play.match.MatchData.HomeTeam : play.match.MatchData.AwayTeam">
                </app-team-icon>
                <app-flag-icon
                    *ngIf="(play.type === 'h2h' || play.type === 'line') && (play.match.MatchData.Sport | lowercase) === 'ten'"
                    class="team-logo shadowed" alt="" [countryCode]="play.bet === 'home' ? play.match.MatchData.PlayerData.player1.country
                        : play.match.MatchData.PlayerData.player2.country">
                </app-flag-icon>
                <div class="total-icon-ctr" *ngIf="play.type === 'total'">
                    <fa-icon class="total-icon  text-highlight-100"
                        [icon]="play.bet === 'over' ? faChevronsUp : faChevronsDown"
                    ></fa-icon>
                </div>
            </div>
            <div class="game-details" [class.no-meta]="singleMatch">
                <div class="play-column">
                    <div class="watchlist-icon" *ngIf="!singleMatch">
                        <app-shortlist-button [matchID]="play.match.MatchData.SIMatchID" [sport]="play.match.MatchData.Sport" [mobile]="false"></app-shortlist-button>
                    </div>
                    <span *ngIf="play.type === 'line'">
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        </span>
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(play.bet === 'home' ? play.match.MatchData.HomeTeam :
                            play.match.MatchData.AwayTeam) }}
                        </span>
                        {{ play.lineValue >= 0 ? '+' : '' }}{{ play.lineValue | number: '1.1-1' }}
                    </span>
                
                    <span *ngIf="play.type === 'total'">
                        {{ play.bet === 'over' ? 'Over' : 'Under' }}
                        {{ play.markValue }}
                    </span>
                
                    <span *ngIf="play.type === 'h2h'">
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        </span>
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(play.bet === 'home' ? play.match.MatchData.HomeTeam :
                            play.match.MatchData.AwayTeam) }}
                        </span>
                        win
                    </span>
                
                    <span *ngIf="play.type === 'firstset'">
                        {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        win first set
                    </span>
                    <div class="value-indi" [style.opacity]="betService.valueFireOpacity(play.edge)">
                        <div class="value-icon" aria-label="Value Identified" role="note"></div>
                    </div>
                </div>
                <div class="meta-info" *ngIf="!singleMatch">
                    <div class="sport-info">
                        <!-- <app-sport-icon [code]="play.match.MatchData.Sport"></app-sport-icon> -->
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) !== 'golf'" href="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule" routerLink="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) === 'golf'" href="/bet-hub/golf/next/schedule" routerLink="/bet-hub/golf/next/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                    </div>
                    <div>
                        &#x2022;
                    </div>
                    <a [href]="generalService.getMatchLink(play.match.MatchData.SIMatchID)">
                        <div class="team-info">
                            <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                                {{ generalService.teamNameDisplay(play.match.MatchData.AwayTeam) }}
                                vs.
                                {{ generalService.teamNameDisplay(play.match.MatchData.HomeTeam) }}
                            </span>
                            <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                                {{ matchService.minUniqueName(play.match, 1, true) }}
                                vs.
                                {{ matchService.minUniqueName(play.match, 2, true) }}
                            </span>
                        </div>
                    </a>
                    <div>
                        &#x2022;
                    </div>
                    <div class="match-time">
                        {{ matchService.formattedDateAlt(play.match.MatchData.UserDate) }}
                    </div>
                </div>    
            </div>
        </div>
        <div class="bet-info">
            <div class="edge-column">
                <div>
                    <div class="edge-title">Probability:</div>
                    <div>{{ (play.winProb * 100) | number: '1.1-1' }}%</div>
                    <div  class="info-tooltip relative"  *ngIf="educationSectionOnPage">
                        <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <div class="tooltip-body right-0 w-80 bg-gray-900 rounded-md">
                                <div class="text-white font-bold">
                                    Probability:
                                </div>
                                <div class="text-white mt-3">
                                    This is the likelihood of this bet winning.
                                </div>
                                <button class="mt-3 text-white w-full rounded h-8 flex items-center justify-center" (click)="jumpToSection('Probabilities')">
                                    Learn More
                                </button>
                            </div>
                    </div>
                </div>
                <div>
                    <div class="edge-title">Edge:</div>
                    <div>{{ play.edge| number: '1.1-1' }}%</div>
                    <div  class="info-tooltip relative"  *ngIf="educationSectionOnPage">
                        <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <div class="tooltip-body right-0 w-80 bg-gray-900 rounded-md">
                                <div class="text-white font-bold">
                                    Edge:
                                </div>
                                <div class="text-white mt-3">
                                    This is the difference between our projection and the implied probability of the bet’s odds.
                                </div>
                                <button class="mt-3 text-white w-full rounded h-8 flex items-center justify-center" (click)="jumpToSection('Edges')">
                                    Learn More
                                </button>
                            </div>
                    </div>
                </div>
            </div>
            <div class="bet-btns-group">
                    <div class="odds-info">
                        <div class="odds-btn"
                            (click)="this.oddsButtonClick()">
                            <img [src]="play.bookmaker | bookIcon" class="book-icon" width="24" height="24">
                            <div class="odds">
                                {{ betService.formatOdds(play.odds) | async }}
                            </div>
                        </div>
                        <!-- <app-quick-bet-prompt [bookmaker]="play.bookmaker" [linkDetails]="play.linkDetails"
                        [tapTarget]="'Bet Now'"></app-quick-bet-prompt> -->
                    </div>
                    
                    <div class="go-to-button"
                        (click)="this.oddsButtonClick()">
                        Go to site
                    </div>
            </div>
        </div>
    </div>
    <div class="value-bet-info compact-only new-layout">

            <div class="top" *ngIf="!singleMatch">
                <div class="meta-info">
                    <div class="sport-info">
                        <!-- <app-sport-icon [code]="play.match.MatchData.Sport"></app-sport-icon> -->
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) !== 'golf'" href="" routerLink="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) === 'golf'" href="" routerLink="/bet-hub/golf/next/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                    </div>
                    <div>
                        &#x2022;
                    </div>
                    <a [href]="generalService.getMatchLink(play.match.MatchData.SIMatchID)">
                        <div class="team-info">
                            <!-- <app-team-icon *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'" class="team-logo" alt=""
                                [sportCode]="play.match.MatchData.Sport" [team]="play.match.MatchData.AwayTeam"></app-team-icon>
                            <app-flag-icon *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" class="team-logo shadowed" alt=""
                                [countryCode]="play.match.MatchData.PlayerData.player1.country"></app-flag-icon> -->
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"> {{ play.match.MatchData.AwayTeam.Abv }}</span>
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" >   {{ matchService.minUniqueName(play.match, 1, true) }}</span>
                            <span> vs. </span>
                            <!-- <app-team-icon *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'" class="team-logo" alt=""
                                [sportCode]="play.match.MatchData.Sport" [team]="play.match.MatchData.HomeTeam"></app-team-icon>
                            <app-flag-icon *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" class="team-logo shadowed" alt=""
                                [countryCode]="play.match.MatchData.PlayerData.player2.country"></app-flag-icon> -->
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">{{ play.match.MatchData.HomeTeam.Abv }}</span>
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">   {{ matchService.minUniqueName(play.match, 2, true) }}</span>

                        </div>
                    </a>
                    <div>
                        &#x2022;
                    </div>
                    <div class="match-time">
                        {{ matchService.formattedDateAlt(play.match.MatchData.UserDate) }}
                    </div>
                </div>
                <div class="watchlist-icon">
                    <app-shortlist-button [matchID]="play.match.MatchData.SIMatchID" [sport]="play.match.MatchData.Sport" [mobile]="true"></app-shortlist-button>
                </div>
            </div>
            <div class="middle">
                <div class="team-logo">
                    <app-team-icon
                        *ngIf="(play.type === 'h2h' || play.type === 'line') && (play.match.MatchData.Sport | lowercase) !== 'ten'"
                        class="team-logo" alt="" [sportCode]="play.match.MatchData.Sport"
                        [team]="play.bet === 'home' ? play.match.MatchData.HomeTeam : play.match.MatchData.AwayTeam">
                    </app-team-icon>
                    <app-flag-icon
                        *ngIf="(play.type === 'h2h' || play.type === 'line') && (play.match.MatchData.Sport | lowercase) === 'ten'"
                        class="team-logo shadowed" alt="" [countryCode]="play.bet === 'home' ? play.match.MatchData.PlayerData.player1.country
                            : play.match.MatchData.PlayerData.player2.country">
                    </app-flag-icon>
                    <div class="total-icon-ctr" *ngIf="play.type === 'total'">
                        <fa-icon class="total-icon  text-highlight-100"
                            [icon]="play.bet === 'over' ? faChevronsUp : faChevronsDown"
                        ></fa-icon>
                    </div>
                </div>
                <div class="play-column">
                    <span *ngIf="play.type === 'line'">
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        </span>
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(play.bet === 'home' ? play.match.MatchData.HomeTeam :
                            play.match.MatchData.AwayTeam) }}
                        </span>
                        {{ play.lineValue >= 0 ? '+' : '' }}{{ play.lineValue | number: '1.1-1' }}
                    </span>
        
                    <span *ngIf="play.type === 'total'">
                        {{ play.bet === 'over' ? 'Over' : 'Under' }}
                        {{ play.markValue }}
                    </span>
        
                    <span *ngIf="play.type === 'h2h'">
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        </span>
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(play.bet === 'home' ? play.match.MatchData.HomeTeam :
                            play.match.MatchData.AwayTeam) }}
                        </span>
                        win
                    </span>
        
                    <span *ngIf="play.type === 'firstset'">
                        {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        win first set
                    </span>
                </div>
            </div>
            <div class="bottom">
                <div>
                    <span>Probability: </span>
                    <span><strong>{{ (play.winProb * 100) | number: '1.1-1' }}%</strong></span>
                    <div class="info-tooltip relative w-8 inline ml-1" *ngIf="educationSectionOnPage">
                        <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <div class="tooltip-body -left-20 w-60 bg-gray-900 rounded-md">
                                <div class="text-white font-bold">
                                    Probability:
                                </div>
                                <div class="text-white mt-3">
                                    This is the likelihood of this bet winning.
                                </div>
                                <button class="mt-3 text-white w-full rounded h-8 flex items-center justify-center" (click)="jumpToSection('Probabilities')">
                                    Learn More
                                </button>
                            </div>
                    </div>
                </div>
                <div>
                    <span class="edge-title">Edge: </span>
                    <span><strong>{{ play.edge | number: '1.1-1' }}%</strong></span>
                    <div class="info-tooltip relative w-8 inline ml-1" *ngIf="educationSectionOnPage">
                        <fa-icon [icon]="faCircleInfo"></fa-icon>
                            <div class="tooltip-body -left-56 w-60 bg-gray-900 rounded-md">
                                <div class="text-white font-bold">
                                    Edge:
                                </div>
                                <div class="text-white mt-3">
                                    This is the difference between our projection and the implied probability of the bet’s odds.
                                </div>
                                <button class="mt-3 text-white w-full rounded h-8 flex items-center justify-center" (click)="jumpToSection('Edges')">
                                    Learn More
                                </button>
                            </div>
                    </div>
                </div>
                <div class="value-indi" [style.opacity]="betService.valueFireOpacity(play.edge)">
                    <div class="value-icon" aria-label="Value Identified" role="note"></div>
                </div>
            </div>
            <div class="bet-btns-group">
                <div class="odds-btn"
                    (click)="this.oddsButtonClick()">
                    <img [src]="play.bookmaker | bookIcon" class="book-icon" width="24" height="24">
                    <div class="odds">
                        {{ betService.formatOdds(play.odds) | async }}
                    </div>
        
                </div>
                <div class="go-to-button"
                    (click)="this.oddsButtonClick()">
                    Go to site
                </div>
            </div>
    </div>
</ng-container>
<!-- Locked State - for non-logged-in and Free Users -->
<ng-container *ngIf="proOnly && (proUser$ | async) === false">
    <div class="value-bet-info new-layout large-only">
        <div class="game-info">
            <div class="team-logo">
                <app-team-icon-pair
                    *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"
                    [backSportCode]="play.match.MatchData.Sport"
                    [backTeam]="play.match.MatchData.AwayTeam"
                    [frontSportCode]="play.match.MatchData.Sport"
                    [frontTeam]="play.match.MatchData.HomeTeam"
                >    
                </app-team-icon-pair>
                <app-flag-icon-pair
                    *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'"
                    [backCountryCode]="play.match.MatchData.PlayerData.player1.country"
                    [frontCountryCode]="play.match.MatchData.PlayerData.player2.country"
                >
                </app-flag-icon-pair>
            </div>
            <div class="game-details">
                <div class="play-column">
                    <div class="watchlist-icon">
                        <app-shortlist-button [matchID]="play.match.MatchData.SIMatchID" [sport]="play.match.MatchData.Sport" [mobile]="false"></app-shortlist-button>
                    </div>
                    <!-- <span *ngIf="play.type === 'line'">
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        </span>
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(play.bet === 'home' ? play.match.MatchData.HomeTeam :
                            play.match.MatchData.AwayTeam) }}
                        </span>
                        {{ play.lineValue >= 0 ? '+' : '' }}{{ play.lineValue | number: '1.1-1' }}
                    </span>
                
                    <span *ngIf="play.type === 'total'">
                        {{ play.bet === 'over' ? 'Over' : 'Under' }}
                        {{ play.markValue }}
                    </span>
                
                    <span *ngIf="play.type === 'h2h'">
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        </span>
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(play.bet === 'home' ? play.match.MatchData.HomeTeam :
                            play.match.MatchData.AwayTeam) }}
                        </span>
                        win
                    </span>
                
                    <span *ngIf="play.type === 'firstset'">
                        {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        win first set
                    </span>
                    <div class="value-indi" [style.opacity]="betService.valueFireOpacity(play.edge)">
                        <div class="value-icon" aria-label="Value Identified" role="note"></div>
                    </div> -->
                    <!-- Generic string for bet -->
                    <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                        {{ generalService.teamNameDisplay(play.match.MatchData.AwayTeam) }}
                        vs.
                        {{ generalService.teamNameDisplay(play.match.MatchData.HomeTeam) }} Best Bet
                    </span>
                    <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                        {{ matchService.minUniqueName(play.match, 1, true) }}
                        vs.
                        {{ matchService.minUniqueName(play.match, 2, true) }} Best Bet
                    </span>
                </div>
                <div class="meta-info">
                    <div class="sport-info">
                        <!-- <app-sport-icon [code]="play.match.MatchData.Sport"></app-sport-icon> -->
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) !== 'golf'" href="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule" routerLink="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) === 'golf'" href="/bet-hub/golf/next/schedule" routerLink="/bet-hub/golf/next/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                    </div>
                    <div>
                        &#x2022;
                    </div>
                    <a [href]="generalService.getMatchLink(play.match.MatchData.SIMatchID)">
                        <div class="team-info">
                            <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                                {{ generalService.teamNameDisplay(play.match.MatchData.AwayTeam) }}
                                vs.
                                {{ generalService.teamNameDisplay(play.match.MatchData.HomeTeam) }}
                            </span>
                            <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                                {{ matchService.minUniqueName(play.match, 1, true) }}
                                vs.
                                {{ matchService.minUniqueName(play.match, 2, true) }}
                            </span>
                        </div>
                    </a>
                    <div>
                        &#x2022;
                    </div>
                    <div class="match-time">
                        {{ matchService.formattedDateAlt(play.match.MatchData.UserDate) }}
                    </div>
                </div>    
            </div>
        </div>
        <div class="bet-info">
            <div class="edge-column">
                <div>
                    <div class="edge-title">Probability:</div>
                    <div>X.X%</div>
                </div>
                <div>
                    <div class="edge-title">Edge:</div>
                    <div>X.X%</div>
                </div>
            </div>
            <div class="bet-btns-group">
                <!-- Pro button -->
                <div class="pro-button" (click)="openProModal()">
                    <img alt="" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6">
                    Get Dimers Pro
                </div>
            </div>
        </div>
    </div>
    <div class="value-bet-info compact-only new-layout">
    
            <div class="top">
                <div class="meta-info">
                    <div class="sport-info">
                        <!-- <app-sport-icon [code]="play.match.MatchData.Sport"></app-sport-icon> -->
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) !== 'golf'" href="" routerLink="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) === 'golf'" href="" routerLink="/bet-hub/golf/next/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                    </div>
                    <div>
                        &#x2022;
                    </div>
                    <a [href]="generalService.getMatchLink(play.match.MatchData.SIMatchID)">
                        <div class="team-info">
                            <!-- <app-team-icon *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'" class="team-logo" alt=""
                                [sportCode]="play.match.MatchData.Sport" [team]="play.match.MatchData.AwayTeam"></app-team-icon>
                            <app-flag-icon *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" class="team-logo shadowed" alt=""
                                [countryCode]="play.match.MatchData.PlayerData.player1.country"></app-flag-icon> -->
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"> {{ play.match.MatchData.AwayTeam.Abv }}</span>
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" >   {{ matchService.minUniqueName(play.match, 1, true) }}</span>
                            <span> vs. </span>
                            <!-- <app-team-icon *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'" class="team-logo" alt=""
                                [sportCode]="play.match.MatchData.Sport" [team]="play.match.MatchData.HomeTeam"></app-team-icon>
                            <app-flag-icon *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" class="team-logo shadowed" alt=""
                                [countryCode]="play.match.MatchData.PlayerData.player2.country"></app-flag-icon> -->
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">{{ play.match.MatchData.HomeTeam.Abv }}</span>
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">   {{ matchService.minUniqueName(play.match, 2, true) }}</span>
    
                        </div>
                    </a>
                    <div>
                        &#x2022;
                    </div>
                    <div class="match-time">
                        {{ matchService.formattedDateAlt(play.match.MatchData.UserDate) }}
                    </div>
                </div>
                <div class="watchlist-icon">
                    <app-shortlist-button [matchID]="play.match.MatchData.SIMatchID" [sport]="play.match.MatchData.Sport" [mobile]="true"></app-shortlist-button>

                </div>
            </div>
            <div class="middle">
                <div class="team-logo">
                    <app-team-icon-pair
                        *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"
                        [backSportCode]="play.match.MatchData.Sport"
                        [backTeam]="play.match.MatchData.AwayTeam"
                        [frontSportCode]="play.match.MatchData.Sport"
                        [frontTeam]="play.match.MatchData.HomeTeam"
                    >    
                    </app-team-icon-pair>
                    <app-flag-icon-pair
                        *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'"
                        [backCountryCode]="play.match.MatchData.PlayerData.player1.country"
                        [frontCountryCode]="play.match.MatchData.PlayerData.player2.country"
                    >
                    </app-flag-icon-pair>
                </div>
                <div class="play-column">
                    <!-- Generic string for bet -->
                    <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                        {{ generalService.teamNameDisplay(play.match.MatchData.AwayTeam) }}
                        vs.
                        {{ generalService.teamNameDisplay(play.match.MatchData.HomeTeam) }} Best Bet
                    </span>
                    <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                        {{ matchService.minUniqueName(play.match, 1, true) }}
                        vs.
                        {{ matchService.minUniqueName(play.match, 2, true) }} Best Bet
                    </span>
                </div>
            </div>
            <div class="bottom">
                <div>
                    <span>Probability: </span>
                    <span><strong>X.X%</strong></span>
                </div>
                <div>
                    <span class="edge-title">Edge: </span>
                    <span><strong>X.X%</strong></span>
                </div>
                <!-- <div class="value-indi" [style.opacity]="betService.valueFireOpacity(play.edge)">
                    <div class="value-icon" aria-label="Value Identified" role="note"></div>
                </div> -->
            </div>
            <div class="bet-btns-group">
                <!-- Pro button -->
                <div class="pro-button" (click)="openProModal()">
                    <img alt="" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6">
                    Get Dimers Pro
                </div>
            </div>
    </div>
</ng-container>
<!-- Loading State - shown while checking user information -->
<ng-container *ngIf="proOnly && (proUser$ | async) === null">
    <div class="value-bet-info new-layout large-only">
        <div class="game-info">
            <div class="team-logo">
                <app-team-icon-pair
                    *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"
                    [backSportCode]="play.match.MatchData.Sport"
                    [backTeam]="play.match.MatchData.AwayTeam"
                    [frontSportCode]="play.match.MatchData.Sport"
                    [frontTeam]="play.match.MatchData.HomeTeam"
                >    
                </app-team-icon-pair>
                <app-flag-icon-pair
                    *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'"
                    [backCountryCode]="play.match.MatchData.PlayerData.player1.country"
                    [frontCountryCode]="play.match.MatchData.PlayerData.player2.country"
                >
                </app-flag-icon-pair>
            </div>
            <div class="game-details">
                <div class="play-column">
                    <div class="watchlist-icon">
                        <app-shortlist-button [matchID]="play.match.MatchData.SIMatchID" [sport]="play.match.MatchData.Sport" [mobile]="false"></app-shortlist-button>

                    </div>
                    <!-- <span *ngIf="play.type === 'line'">
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        </span>
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(play.bet === 'home' ? play.match.MatchData.HomeTeam :
                            play.match.MatchData.AwayTeam) }}
                        </span>
                        {{ play.lineValue >= 0 ? '+' : '' }}{{ play.lineValue | number: '1.1-1' }}
                    </span>
                
                    <span *ngIf="play.type === 'total'">
                        {{ play.bet === 'over' ? 'Over' : 'Under' }}
                        {{ play.markValue }}
                    </span>
                
                    <span *ngIf="play.type === 'h2h'">
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                            {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        </span>
                        <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                            {{ generalService.teamNameDisplay(play.bet === 'home' ? play.match.MatchData.HomeTeam :
                            play.match.MatchData.AwayTeam) }}
                        </span>
                        win
                    </span>
                
                    <span *ngIf="play.type === 'firstset'">
                        {{ matchService.minUniqueName(play.match, play.bet === 'home' ? 1 : 2, true) }}
                        win first set
                    </span>
                    <div class="value-indi" [style.opacity]="betService.valueFireOpacity(play.edge)">
                        <div class="value-icon" aria-label="Value Identified" role="note"></div>
                    </div> -->
                    <!-- Generic string for bet -->
                    <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                        {{ generalService.teamNameDisplay(play.match.MatchData.AwayTeam) }}
                        vs.
                        {{ generalService.teamNameDisplay(play.match.MatchData.HomeTeam) }} Best Bet
                    </span>
                    <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                        {{ matchService.minUniqueName(play.match, 1, true) }}
                        vs.
                        {{ matchService.minUniqueName(play.match, 2, true) }} Best Bet
                    </span>
                </div>
                <div class="meta-info">
                    <div class="sport-info">
                        <!-- <app-sport-icon [code]="play.match.MatchData.Sport"></app-sport-icon> -->
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) !== 'golf'" href="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule" routerLink="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) === 'golf'" href="/bet-hub/golf/next/schedule" routerLink="/bet-hub/golf/next/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                    </div>
                    <div>
                        &#x2022;
                    </div>
                    <a [href]="generalService.getMatchLink(play.match.MatchData.SIMatchID)">
                        <div class="team-info">
                            <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                                {{ generalService.teamNameDisplay(play.match.MatchData.AwayTeam) }}
                                vs.
                                {{ generalService.teamNameDisplay(play.match.MatchData.HomeTeam) }}
                            </span>
                            <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                                {{ matchService.minUniqueName(play.match, 1, true) }}
                                vs.
                                {{ matchService.minUniqueName(play.match, 2, true) }}
                            </span>
                        </div>
                    </a>
                    <div>
                        &#x2022;
                    </div>
                    <div class="match-time">
                        {{ matchService.formattedDateAlt(play.match.MatchData.UserDate) }}
                    </div>
                </div>    
            </div>
        </div>
        <div class="bet-info">
            <div class="bet-btns-group">
                
            </div>
        </div>
    </div>
    <div class="value-bet-info compact-only new-layout">
    
            <div class="top">
                <div class="meta-info">
                    <div class="sport-info">
                        <!-- <app-sport-icon [code]="play.match.MatchData.Sport"></app-sport-icon> -->
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) !== 'golf'" href="" routerLink="/bet-hub/{{ (play.match.MatchData.Sport | lowercase) }}/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                        <a *ngIf="(play.match.MatchData.Sport | lowercase) === 'golf'" href="" routerLink="/bet-hub/golf/next/schedule">
                            <span>{{ play.match.MatchData.Sport | uppercase }}</span>
                        </a>
                    </div>
                    <div>
                        &#x2022;
                    </div>
                    <a [href]="generalService.getMatchLink(play.match.MatchData.SIMatchID)">
                        <div class="team-info">
                            <!-- <app-team-icon *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'" class="team-logo" alt=""
                                [sportCode]="play.match.MatchData.Sport" [team]="play.match.MatchData.AwayTeam"></app-team-icon>
                            <app-flag-icon *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" class="team-logo shadowed" alt=""
                                [countryCode]="play.match.MatchData.PlayerData.player1.country"></app-flag-icon> -->
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"> {{ play.match.MatchData.AwayTeam.Abv }}</span>
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" >   {{ matchService.minUniqueName(play.match, 1, true) }}</span>
                            <span> vs. </span>
                            <!-- <app-team-icon *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'" class="team-logo" alt=""
                                [sportCode]="play.match.MatchData.Sport" [team]="play.match.MatchData.HomeTeam"></app-team-icon>
                            <app-flag-icon *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'" class="team-logo shadowed" alt=""
                                [countryCode]="play.match.MatchData.PlayerData.player2.country"></app-flag-icon> -->
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">{{ play.match.MatchData.HomeTeam.Abv }}</span>
                            <span class="team-name" *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">   {{ matchService.minUniqueName(play.match, 2, true) }}</span>
    
                        </div>
                    </a>
                    <div>
                        &#x2022;
                    </div>
                    <div class="match-time">
                        {{ matchService.formattedDateAlt(play.match.MatchData.UserDate) }}
                    </div>
                </div>
                <div class="watchlist-icon">
                    <app-shortlist-button [matchID]="play.match.MatchData.SIMatchID" [sport]="play.match.MatchData.Sport" [mobile]="true"></app-shortlist-button>

                </div>
            </div>
            <div class="middle">
                <div class="team-logo">
                    <app-team-icon-pair
                        *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'"
                        [backSportCode]="play.match.MatchData.Sport"
                        [backTeam]="play.match.MatchData.AwayTeam"
                        [frontSportCode]="play.match.MatchData.Sport"
                        [frontTeam]="play.match.MatchData.HomeTeam"
                    >    
                    </app-team-icon-pair>
                    <app-flag-icon-pair
                        *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'"
                        [backCountryCode]="play.match.MatchData.PlayerData.player1.country"
                        [frontCountryCode]="play.match.MatchData.PlayerData.player2.country"
                    >
                    </app-flag-icon-pair>
                </div>
                <div class="play-column">
                    <!-- Generic string for bet -->
                    <span *ngIf="(play.match.MatchData.Sport | lowercase) !== 'ten'">
                        {{ generalService.teamNameDisplay(play.match.MatchData.AwayTeam) }}
                        vs.
                        {{ generalService.teamNameDisplay(play.match.MatchData.HomeTeam) }} Best Bet
                    </span>
                    <span *ngIf="(play.match.MatchData.Sport | lowercase) === 'ten'">
                        {{ matchService.minUniqueName(play.match, 1, true) }}
                        vs.
                        {{ matchService.minUniqueName(play.match, 2, true) }} Best Bet
                    </span>
                </div>
            </div>
            <div class="bottom">
                <!-- <div>
                    <span>Probability: </span>
                    <span><strong>{{ (play.winProb * 100) | number: '1.1-1' }}%</strong></span>
                </div>
                <div>
                    <span class="edge-title">Edge: </span>
                    <span><strong>{{ play.edge | number: '1.1-1' }}%</strong></span>
                </div>
                <div class="value-indi" [style.opacity]="betService.valueFireOpacity(play.edge)">
                    <div class="value-icon" aria-label="Value Identified" role="note"></div>
                </div> -->
            </div>
            <div class="bet-btns-group">
                <!-- Pro button -->
                
            </div>
    </div>
</ng-container>
